@import "fonts";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  position: relative;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 25px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 1630px;
  margin: 0 auto;
  padding: 0 15px;
}

header {
  width: 100%;
  border-bottom: 4px solid #000;
  margin-bottom: 30px;

  .header {
    &__wrap {
      padding-top: 10px;
      padding-bottom: 30px;
    }
    &__logo {
      display: block;
      height: 50px;
      width: 100%;
      text-align: center;
      margin-bottom: 30px;

      img {
        height: 100%;
        width: auto;
      }
    }
    &__nav {
      position: relative;
      text-align: center;

      ul {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        font-weight: 600;
        background-color: #fff;
      }
      li {
        margin: 0 50px;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__burger {
      display: none;
      position: relative;
      width: 30px;
      height: 17px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #000;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 14px;
        }
      }
    }
  }
}

main {
  width: 100%;
  flex: 1;
  margin-bottom: 100px;
}

.first-block {
  width: 100%;
  background-color: #000;
  padding: 10px 0;
  margin-bottom: 30px;

  &__wrap {
    width: 100%;
    max-width: 1210px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__text {
    flex: 1;
    font-size: 40px;
    font-weight: 900;
    color: #fff;
  }
  &__image {
    width: 550px;
    height: 330px;
    margin-left: 140px;

    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.news {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__list {
    flex: 1;
    margin-right: 90px;
  }
  &__caption {
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 24px;
  }
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #000;

    &:last-child {
      border-bottom: none;
    }
    .item {
      &__image {
        width: 200px;
        height: 130px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-right: 10px;
      }
      &__meta {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }
      &__title {
        font-weight: 600;
        
        &:hover {
          text-decoration: underline;
        }
      }
      &__views {
        display: block;
        position: relative;
        font-size: 20px;
        color: #868686;
        line-height: 35px;
        padding-left: 66px;

        &:before {
          position: absolute;
          display: block;
          content: '';
          width: 56px;
          height: 35px;
          left: 0;
          top: 0;
          background-image: url('../img/icon-diagram.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
  }
  &__first {
    flex-basis: 50%;

    .first {
      &__image {
        width: 100%;
        height: auto;

        img {
          width: 100%;
          height: auto;
        }
      }
      &__text {
        position: relative;
        display: block;
        max-width: 580px;
        font-size: 40px;
        font-weight: 900;
        background-color: #fff;
        padding: 55px 15px;
        margin: -60px 0 0 30px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.content {
  & > * {
    margin-bottom: 30px;
  }

  h1, h2, h3 {
    font-weight: 700;
    text-align: center;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 28px;
  }
  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin: 0 auto 12px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 5px;
      border: 1px solid #000;
      text-align: center;
    }
  }
}

footer {
  padding: 8px 15px;

  .copyright {
    font-size: 20px;
    text-align: center;
  }
}

@import "media";