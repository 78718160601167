@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.woff2") format("woff2"), url("../fonts/montserrat.woff") format("woff"), url("../fonts/montserrat.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratbold.woff2") format("woff2"), url("../fonts/montserratbold.woff") format("woff"), url("../fonts/montserratbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratblack.woff2") format("woff2"), url("../fonts/montserratblack.woff") format("woff"), url("../fonts/montserratblack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  position: relative;
  overflow-x: hidden;
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 25px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 1630px;
  margin: 0 auto;
  padding: 0 15px;
}
header {
  width: 100%;
  border-bottom: 4px solid #000;
  margin-bottom: 30px;
}
header .header__wrap {
  padding-top: 10px;
  padding-bottom: 30px;
}
header .header__logo {
  display: block;
  height: 50px;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}
header .header__logo img {
  height: 100%;
  width: auto;
}
header .header__nav {
  position: relative;
  text-align: center;
}
header .header__nav ul {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  font-weight: 600;
  background-color: #fff;
}
header .header__nav li {
  margin: 0 50px;
}
header .header__nav a:hover {
  text-decoration: underline;
}
header .header__burger {
  display: none;
  position: relative;
  width: 30px;
  height: 17px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 7px;
}
header .header__burger span:nth-child(3) {
  top: 14px;
}
main {
  width: 100%;
  flex: 1;
  margin-bottom: 100px;
}
.first-block {
  width: 100%;
  background-color: #000;
  padding: 10px 0;
  margin-bottom: 30px;
}
.first-block__wrap {
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.first-block__text {
  flex: 1;
  font-size: 40px;
  font-weight: 900;
  color: #fff;
}
.first-block__image {
  width: 550px;
  height: 330px;
  margin-left: 140px;
  overflow: hidden;
}
.first-block__image img {
  width: 100%;
  height: auto;
}
.news {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.news__list {
  flex: 1;
  margin-right: 90px;
}
.news__caption {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 24px;
}
.news__item {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #000;
}
.news__item:last-child {
  border-bottom: none;
}
.news__item .item__image {
  width: 200px;
  height: 130px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 10px;
}
.news__item .item__meta {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.news__item .item__title {
  font-weight: 600;
}
.news__item .item__title:hover {
  text-decoration: underline;
}
.news__item .item__views {
  display: block;
  position: relative;
  font-size: 20px;
  color: #868686;
  line-height: 35px;
  padding-left: 66px;
}
.news__item .item__views:before {
  position: absolute;
  display: block;
  content: '';
  width: 56px;
  height: 35px;
  left: 0;
  top: 0;
  background-image: url('../img/icon-diagram.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.news__first {
  flex-basis: 50%;
}
.news__first .first__image {
  width: 100%;
  height: auto;
}
.news__first .first__image img {
  width: 100%;
  height: auto;
}
.news__first .first__text {
  position: relative;
  display: block;
  max-width: 580px;
  font-size: 40px;
  font-weight: 900;
  background-color: #fff;
  padding: 55px 15px;
  margin: -60px 0 0 30px;
}
.news__first .first__text:hover {
  text-decoration: underline;
}
.content > * {
  margin-bottom: 30px;
}
.content h1,
.content h2,
.content h3 {
  font-weight: 700;
  text-align: center;
}
.content h1 {
  font-size: 40px;
}
.content h2 {
  font-size: 30px;
}
.content h3 {
  font-size: 28px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin: 0 auto 12px;
}
.content table {
  width: 100%;
  border-collapse: collapse;
}
.content table td {
  padding: 5px;
  border: 1px solid #000;
  text-align: center;
}
footer {
  padding: 8px 15px;
}
footer .copyright {
  font-size: 20px;
  text-align: center;
}
@media screen and (max-width: 1199px) {
  .news {
    display: block;
  }
  .news__list {
    margin-right: 0;
  }
}
@media screen and (max-width: 991px) {
  header .header__nav li {
    margin: 0 30px;
  }
  .first-block__wrap {
    flex-wrap: wrap-reverse;
  }
  .first-block__image {
    width: 100%;
    height: auto;
    max-height: 330px;
    margin-left: 0;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  header {
    border-bottom: none;
    margin-bottom: 10px;
  }
  header .header__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  header .header__logo {
    width: auto;
    height: 30px;
    margin-bottom: 0;
  }
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(27deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-27deg);
    transform-origin: left;
  }
  header .header__nav {
    position: absolute;
    top: -100%;
    left: 70px;
    padding: 10px 20px;
    width: 200px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    z-index: 999999;
  }
  header .header__nav.active {
    top: 0;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav:before {
    display: none;
  }
  header .header__nav ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    background-color: transparent;
  }
  header .header__nav ul li {
    margin: 0 0 25px 0;
  }
  footer .copyright {
    font-size: 15px;
  }
}
@media screen and (max-width: 480px) {
  .news__item {
    display: block;
  }
  .news__item .item__image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .news__item .item__title {
    margin-bottom: 20px;
  }
}
