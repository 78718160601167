@media screen and (max-width: 1199px) {
  .news {
    display: block;

    &__list {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  header {
    .header{
      &__nav {
        li {
          margin: 0 30px;
        }
      }
    }
  }

  .first-block {
    &__wrap {
      flex-wrap: wrap-reverse;
    }
    &__image {
      width: 100%;
      height: auto;
      max-height: 330px;
      margin-left: 0;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    border-bottom: none;
    margin-bottom: 10px;

    .header {
      &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
      }
      &__logo {
        width: auto;
        height: 30px;
        margin-bottom: 0;
      }
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(27deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-27deg);
              transform-origin: left;
            }
          }
        }
      }

      &__nav {
        position: absolute;
        top: -100%;
        left: 70px;
        padding: 10px 20px;
        width: 200px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        background-color: #fff;
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease-in-out;
        z-index: 999999;

        &.active {
          top: 0;
          opacity: 1;
          visibility: visible;
        }
        &:before {
          display: none;
        }
        ul {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 0;
          background-color: transparent;

          li {
            margin: 0 0 25px 0;
          }
        }
      }
    }
  }

  footer {
    .copyright {
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 480px) {
  .news {
    &__item {
      display: block;

      .item {
        &__image {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }
        &__title {
          margin-bottom: 20px;
        }
      }
    }
  }
}