@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.woff2") format("woff2"),
      url("../fonts/montserrat.woff") format("woff"),
      url("../fonts/montserrat.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratbold.woff2") format("woff2"),
      url("../fonts/montserratbold.woff") format("woff"),
      url("../fonts/montserratbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratblack.woff2") format("woff2"),
      url("../fonts/montserratblack.woff") format("woff"),
      url("../fonts/montserratblack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}